/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Static from "./Statics";
import Navbar from "../../components/Navbar";
import Membership from "./Membership";
import { CreateMembership } from "./Membership/Create";
import Multiplier from "./Multipliers";
export const ProductIndex = () => {
  const routes = [
    // { name: "Productos", route: "/productos" },
    { name: "Multiplicadores", route: "/productos/multiplicadores" },
    { name: "Membresias", route: "/productos" },
    { name: "Estaticos", route: "/productos/estaticos" },
  ];

  return <Navbar routes={routes} />;
};

export const ProductRoutes = [
  //// { path: "", Element: Products },
  { path: "multiplicadores", Element: Multiplier },
  { path: "estaticos", Element: Static },
  { path: "", Element: Membership },
  { path: "membresias/crear", Element: CreateMembership },
];
