/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleGrid, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Builder from "../../components/forms";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { saveMembershipPlanAsync } from "../../redux/membership/actions";

export const MembershipCreate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields([
      {
        name: "name",
        label: "Nombre",
        required: true,
        defaultValue: "",
      },
      {
        name: "slug",
        label: "Slug",
        required: true,
        defaultValue: "",
      },
      {
        name: "description",
        label: "Descripción",
        required: false,
        defaultValue: "",
        type: "textarea",
      },
      {
        name: "content",
        label: "Contenido",
        required: false,
        defaultValue: "",
        type: "textarea",
      },
      {
        name: "price",
        label: "Precio",
        required: true,
        defaultValue: "",
      },
      {
        name: "months",
        label: "Meses",
        required: true,
        defaultValue: "",
      },
      {
        name: "currency",
        label: "Moneda",
        required: true,
        defaultValue: "",
      },
      {
        name: "stock",
        label: "Stock",
        required: true,
        defaultValue: "",
      },
    ]);
  }, [id]);

  const savePlan = (data) => {
    data["product_id"] = id;
    dispatch(saveMembershipPlanAsync(data));
  };

  return (
    <>
      <Box p={5} shadow="md" borderWidth="1px">
        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Builder
            fields={fields}
            message="Crear variante"
            onClick={savePlan}
          />
        </SimpleGrid>
      </Box>
    </>
  );
};
