import React from "react";
import { Edit, Create } from "../../components/buttons";
import DataTables from "../../components/Datatable";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import { getMembershipsPlanAsync } from "../../redux/membership/actions";

export const Membership = () => {
  const { id } = useParams();
  const dispatchable = (query) => {
    return getMembershipsPlanAsync({
      ...query,
      product_id: id,
    });
  };

  const selectable = (state) => state.membership.memberships;

  const columns = (row) => {
    return {
      name: row.name,
      slug: row.slug,
      description: row.description || "-",
      content: row.content || "-",
      price: `$ ${row.price} ${row.currency}`,
      months: `${row.months} meses`,
      currency: row.currency,
      stock: row.stock,
      opciones: (
        <div>
          <Edit route={`/membresias/${row.id}/editar`} />
        </div>
      ),
    };
  };

  return (
    <Stack style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
      <Create route={`/membresias/${id}/crear`} />

      <DataTables
        columns={columns}
        dispatchable={dispatchable}
        selectable={selectable}
        title="Gestion de membresias"
      />
    </Stack>
  );
};
