import { Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Builder from "../../../components/forms";
import { getCategoriesAsync } from "../../../redux/category/actions";
import { saveMembershipAsync } from "../../../redux/membership/actions";

export const CreateMembership = () => {
  const [fields, setFields] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesAsync([]));
  }, [dispatch]);

  useEffect(() => {
    setFields([
      {
        name: "name",
        label: "Nombre",
        required: true,
      },

      {
        name: "slug",
        label: "Slug",
        required: true,
      },
      {
        name: "short_description",
        label: "Descripcion corta",
        required: true,
      },
      {
        name: "description",
        label: "Descripcion",
        required: true,
      },
      {
        name: "content",
        editor: true,
        label: "Contenido principal",
        required: true,
      },
    ]);
  }, []);

  const saveVersion = (data) => {
    data.active = 0;
    dispatch(saveMembershipAsync(data));
  };

  return (
    <Stack style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
      <Stack p="4" boxShadow="lg" m="4" borderRadius="sm">
        <Stack direction="row" alignItems="center">
          <Text fontWeight="semibold">Crear membresia</Text>
        </Stack>

        <Builder
          fields={fields}
          message="Crear membresia"
          onClick={saveVersion}
        />
      </Stack>
    </Stack>
  );
};
