import { authAxios } from "./../../config/axios";

class Suscription {
  get = (params = []) => {
    return authAxios.get(`/plan/static/products`, {
      params,
    });
  };

  show = (id) => {
    return authAxios.get(`/plan/static/products/${id}`);
  };

  update = (id, data) => {
    return authAxios.put(`/plan/static/products/${id}`, data);
  };

  save = (data) => {
    let formData = new FormData();

    for (let k in data) formData.append(k, data[k]);

    return authAxios.post(`/plan/static/products`, formData);
  };

  updateImage = (id, image) => {
    let formData = new FormData();
    formData.append("image", image);
    return authAxios.post(`/plan/static/products/${id}/image`, formData);
  };
}

export default new Suscription();
