import { Stack, Button } from "@chakra-ui/react";
import React from "react";
import { Edit, Show } from "../../../components/buttons";
import DataTables from "../../../components/Datatable";
import { useNavigate } from "react-router-dom";
import { getMembershipsAsync } from "../../../redux/membership/actions";
import Create from "../../../components/buttons/Create";
export default function Membership() {
  const navigate = useNavigate();

  const dispatchable = (query) => {
    return getMembershipsAsync({
      ...query,
      select: "id,name,slug,sold,variant",
      with: "logo",

      membership: 1,
    });
  };

  const selectable = (state) => state.membership.memberships;

  const columns = (row) => {
    let collectives = {};

    return {
      img: row.logo ? (
        <img width="100px" alt="" src={row.logo[0]?.image || ""} />
      ) : (
        ""
      ),
      name: row.name,
      slug: row.slug,
      sold: row.sold || 0,
      stock: row.stock || "Sin limite",
      planes: (
        <Button onClick={() => navigate(`/membresias/${row.id}`)}>
          Ver variantes
        </Button>
      ),
      ...collectives,
      opciones: (
        <div>
          <Show route={`/productos/${row.id}`} />
          <Edit route={`/productos/${row.id}/editar`} />
        </div>
      ),
    };
  };

  return (
    <Stack style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
      <Create route="membresias/crear" title="Crear membresia " />
      <DataTables
        columns={columns}
        dispatchable={dispatchable}
        selectable={selectable}
        title="Gestion de membresias"
      />
    </Stack>
  );
}
