/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleGrid, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Builder from "../../components/forms";
import { useParams } from "react-router-dom";
import { membershipApi } from "../../api";
import { toast } from "react-toastify";
export const MembershipEdit = () => {
  const { id } = useParams();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    membershipApi.show(id).then((res) => {
      let mem = res.data.data;

      setFields([
        {
          name: "name",
          label: "Nombre",
          required: true,
          defaultValue: mem.name,
        },
        {
          name: "slug",
          label: "Slug",
          required: true,
          defaultValue: mem.slug,
        },
        {
          name: "description",
          label: "Descripción",
          required: false,
          defaultValue: mem.description,
          type: "textarea",
        },
        {
          name: "content",
          label: "Contenido",
          required: false,
          defaultValue: mem.content,
          type: "textarea",
        },
        {
          name: "price",
          label: "Precio",
          required: true,
          defaultValue: mem.price,
        },
        {
          name: "months",
          label: "Meses",
          required: true,
          defaultValue: mem.months,
        },
        {
          name: "currency",
          label: "Moneda",
          required: true,
          defaultValue: mem.currency,
        },
        {
          name: "stock",
          label: "Stock",
          required: true,
          defaultValue: mem.stock,
        },
      ]);
    });
  }, [id]);

  const savePlan = (data) => {
    membershipApi.update(id, data).then((res) => {
      toast.success("Membresia editada correctamente", { theme: "dark" });
    });
  };

  return (
    <>
      <Box p={5} shadow="md" borderWidth="1px">
        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Builder
            fields={fields}
            message="Editar membresia"
            onClick={savePlan}
          />
        </SimpleGrid>
      </Box>
    </>
  );
};
