import { Stack } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import DataTables from "../../../components/Datatable";
import { getUserHistoryAsync } from "../../../redux/user/actions";

export const History = () => {
  const { id } = useParams();

  const selectable = (state) => state.user.histories;

  const columns = (row) => {
    return {
      Rango: row.rangue?.name,
      Fecha: row.created_at,
    };
  };

  const dispatchable = (query) => {
    return getUserHistoryAsync({
      ...query,
      user_id: id,
      with: "rangue:id,name",
    });
  };

  return (
    <Stack bg={"white"}>
      <DataTables
        columns={columns}
        dispatchable={dispatchable}
        selectable={selectable}
        title="Gestion de historia"
      />
    </Stack>
  );
};
