import { createReducer } from "@reduxjs/toolkit";
import types from "./actionTypes";

const initialState = {
  memberships: [],
  suscriptions: [],
  suscription: {},
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.getMemberships, (state, action) => {
      state.memberships = action.payload;
    })
    .addCase(types.getSuscriptions, (state, action) => {
      state.suscriptions = action.payload;
    })
    .addCase(types.getSuscription, (state, action) => {
      state.suscription = action.payload;
    });
});
