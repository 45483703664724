import { productApi, membershipApi } from "../../api";
import types from "./actionTypes";
import { toast } from "react-toastify";

///productos
export const getMembershipsAsync =
  (params = {}) =>
  async (dispatch) => {
    const response = await productApi.get(params);
    dispatch(types.getMemberships(response.data.data));
  };

export const getMembershipsPlanAsync =
  (params = {}) =>
  async (dispatch) => {
    const response = await membershipApi.get(params);
    dispatch(types.getMemberships(response.data.data));
  };

export const saveMembershipPlanAsync = (data) => async (dispatch, state) => {
  const response = await membershipApi.save(data);
  toast.success("Suscription guardado correctamente", { theme: "dark" });
  const memberships = JSON.parse(JSON.stringify(state().membership.membership));

  memberships.data.push(response.data.data);
  dispatch(types.getMemberships(memberships));
};

export const saveMembershipAsync = (data) => async (dispatch, state) => {
  alert("aqui");
  data.membership = 1;
  data["variant"] = "App\\Models\\Membership\\Membership";

  const response = await productApi.save(data);
  toast.success("Membresia guardado correctamente", { theme: "dark" });

  let Memberships = JSON.parse(JSON.stringify(state().membership.memberships));
  Memberships.data.push(response.data.data);
  dispatch(types.getMemberships(Memberships));
};
